import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

const Viewfinanciallead = ({ show, handleClose }) => {
    // Accessing the financeconv data from the Redux store
   
    const userReducer = useSelector(state => state.financeconv);
    const rowData = userReducer.user ? userReducer.user : {};
    return (
        <Modal show={show} onHide={handleClose}>
           <Modal.Header>
                    <Modal.Title>{"View Financial Categories"}</Modal.Title>
                    <i className="fa fa-times close_icon_modal" onClick={handleClose}></i>
                </Modal.Header>
            <Modal.Body>
                <div className="">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-3"><label className="form-label">Name</label></div>
                                    <div className="col-1"><label className="form-label">:</label></div>
                                    <div className="col-8"><span className="form-label">{rowData.name ? rowData.name : "---"}</span></div>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default Viewfinanciallead;
