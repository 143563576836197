import React, { useState, useEffect, useRef,useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {fetchLoopnetData,fetchLoopnetDataSpinner} from "../../Action/loopnetActions";
import {getFinancialLeadList} from '../../Action/financialledAction';
import RowSelectAggridComponent from "../../Utilities/Ag-Grid/rowselectaggridcomponents";
import {formatPhoneNumber,getSortModelAlternative,loopnetCSVName,replaceLoopnetPDFName} from "../../Common/validate";
import SpinnerComponent from "../../Utilities/Spinner/spinner";
import { Modal, Button,OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import ImportedURL from "../../Common/api";
import Select from "react-select";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Error, Success } from "../../Common/swal";
import moment, { max, min } from "moment";
import { getCityAndStates } from "../../Action/citiesAction";
import ViewLoopNet from "./viewloopnet";
import Swal from "sweetalert2";
import MultiSelectComponent from "../../Utilities/SelectOption/multiselect";




// Access the variable


const defaultColDef = {
  editable: false,
  sortable: true,
  resizable: true,
  filter: true,
  flex: 1,
  minWidth: 100,
  filterParams: { newRowsAction: "keep" },
};

const propertyUseOptions = [
  { value: "business", label: "Business For Sale", name: "propertUse" },
  { value: "owner", label: "Vacant/Owner-User Properties", name: "propertUse" },
  { value: "investment", label: "Investment Properties", name: "propertUse" },
];

const getImageAsBase64 = async (url) => {
  try {
    if (url) {
      const response = await fetch(url);
      if (!response.ok) {
        return null;
      }
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};


const jsonData = JSON.parse(process.env.REACT_APP_JSON_DATA);
//console.log(jsonData); 

const ListLoopnet = () => {
  const gridApiRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.loopnet);
  const financeleaddata = useSelector((state) => state.financelead); //financelead uses rootreducer name
  const citydata = useSelector((state) => state.cities);
  const statelist = citydata.selectstates ? citydata.selectstates : [];
  const citylist = citydata.selectcities ? citydata.selectcities : [];
  const rowData = data.listloopnet ? data.listloopnet : [];
  const financeleadUser=financeleaddata.financeLeads?financeleaddata.financeLeads:[];
  const total = data.total ? data.total : 0;
  const spinner = data.spinner ? data.spinner : false;
  const initialTrigger = data.initialTrigger ? data.initialTrigger : false;
 

  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [progressbar, setProgressbar] = useState({ value: 0, max: 0 });
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [initialTriggle, setInitialTriggle] = useState(false);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("");
  const [tabType, setTabType] = useState("new");

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [financelead, setfinancelead] = useState("");
  const [propertType, setPropertType] = useState([]);
  const [propertUse, setPropertUse] = useState("");
  const [minPrice, setMinPrice] = useState("500000");
  const [maxPrice, setMaxPrice] = useState("5000000");
  const [propertTypeSelectAll, setPropertTypeSelectAll] = useState(true);
  const [resetFilter, setResetFilter] = useState(false);
  const [listSpinner, setListSpinner] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [sendMailSpinner, setSendMailSpinner] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showOldModal, setShowOldModal] = useState(false);
  const [downpaymentError, setDownPaymentError] = useState(false);
  const [termofloanError, setTermOfLoanError] = useState(false);
  const [FinancialError, setFinancialError] = useState(false);
  const [interestpercentageError, setInterestPercentageError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [downpayment, setDownPayment] = useState("");
  const [termofloan, setTermOfLoan] = useState("25");
  const [interestpercentage, setInterestPercentage] = useState("11");
  const [additionalcost, setAdditionalCosts] = useState("0");
  const [listingId, setListingId] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [viewData, setViewData] = useState({});
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [financialOptions, setFinancialOptions] = React.useState([]);

  const propertyTypeOptions = [
    { value: "office", label: "Office", name: "propertType" },
    { value: "industrial", label: "Industrial", name: "propertType" },
    { value: "retail", label: "Retail", name: "propertType" },
    { value: "restaurant", label: "Restaurant", name: "propertType" },
    { value: "shopping center", label: "Shopping Center", name: "propertType" },
    { value: "multi-family", label: "Multifamily", name: "propertType" },
    { value: "specialty", label: "Specialty", name: "propertType" },
    { value: "health care", label: "Health Care", name: "propertType" },
    { value: "hospitality", label: "Hospitality", name: "propertType" },
    {
      value: "sports & entertainment",
      label: "Sports & Entertainment",
      name: "propertType",
    },
    { value: "land", label: "Land", name: "propertType" },
    {
      value: "residential income",
      label: "Residential Income",
      name: "propertType",
    },
  ];
  const modalBodyRef = useRef(null);
   // Transform JSON into the tabData structure
   const [financeLead, setFinanceLead] = useState(null);

  const initialTabData = jsonData.reduce((acc, { name }) => {
    acc[name] = {
      downpayment: "",
      termofloan: "",
      interestpercentage: "",
      financeRows: [{ selectValue: null, textValue: "" }],
     // financelead: null,
    };
    return acc;
  }, {});

  const [activeTab, setActiveTab] = useState(jsonData[0].name); // Default to the first tab
  const [tabData, setTabData] = useState(initialTabData);
  const [currentTabState, setCurrentTabState] = useState(initialTabData[activeTab]);
  

//const [currentTabState, setCurrentTabState] = useState(tabData[activeTab]);
const [financeRows, setFinanceRows] = useState([
  { selectValue: null, textValue: "0" },
]);

 const [isValid, setIsValid] = useState(true);
//const { fincialleadOptions } = currentTabState[activeTab]; // Get the options specific to the active tab
 // Define the options for financing leads
 const fincialleadOptions = [];
 if (financeleadUser && Array.isArray(financeleadUser) && financeleadUser.length > 0) {
   financeleadUser.forEach((item) => {
     fincialleadOptions.push({
       value: item._id,
       label: item.name,
       name: "financelead",
     });
   });
 }

 // Add a default option if 'financelead' variable has a value
 if (currentTabState[activeTab]?.financelead?.value) {
  fincialleadOptions.unshift({ label: "Select...", value: "Deselect", name: 'financelead' });
}
  const showChargeCodeAll = propertyTypeOptions.filter(
    (e) => !(propertType && propertType.some((o) => e.value == o.value))
  );
  if (
    showChargeCodeAll.length > 1 &&
    propertyTypeOptions.length != propertType.length &&
    propertTypeSelectAll
  ) {
    propertyTypeOptions.unshift({
      label: "All",
      value: "All",
      name: "propertType",
    });
  }

 
  const onFetchData = (query = {}) => {
    const statusPropertyType = propertType.some((o) => o.label === "All");
    const formData = {
      tabType: tabType ? tabType : undefined,
      perPage: perPage,
      currentPage: currentPage,
      filter: filter,
      sort: sort,
      propertType: statusPropertyType
        ? propertyTypeOptions.map((e) => e.value)
        : propertType.map((e) => e.value),
      propertUse: propertUse && propertUse.value ? propertUse.value : "",
      state: state && state.value ? state.value : "",
      statecode: state && state.code ? state.code : "",
      city: city && city.value ? city.value : "",
      minPrice: initialTriggle ? (minPrice ? minPrice : undefined) : undefined,
      maxPrice: initialTriggle ? (maxPrice ? maxPrice : undefined) : undefined,
      ...query,
    };
    //console.log(formData);
    dispatch(fetchLoopnetDataSpinner());
    dispatch(fetchLoopnetData(formData));
   
  };

//   const onFetchFinanceLeadData = () => {
//     const formData = {
//         perPage: perPage,          // Assuming perPage is defined in the component
//         currentPage: currentPage,  // Assuming currentPage is defined in the component
//         filter: filter,            // Assuming filter is defined in the component
//         sort: sort                 // Assuming sort is defined in the component
//     };
//     dispatch(fetchFinancialLeadSpinner());
//     dispatch(fetchfinancealleadData(formData));
// };

  useEffect(() => {
   // localStorage.setItem("tabType", "new");
    dispatch(getCityAndStates());
    dispatch(getFinancialLeadList());
    onFetchData();
    onFetchFincncialCost();
  }, [dispatch, perPage, currentPage, filter, sort,
   // activeTab
  ]);

  useEffect(() => {
    // Load initial tab data from tabData
    setCurrentTabState(tabData[activeTab]);
  }, [activeTab, tabData]);
  

//   const download = `<button title="Download" type="button" class="btn btn-icon" data-action-type="Download" data-toggle="modal" data-target="#viewmodal"><i class="fas fa-download" data-action-type="Download"></i></button>`;
//   const createDownload = `<button title="Create-PDF" type="button" class="btn btn-icon" data-action-type="Create-PDF" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-plus-circle" data-action-type="Create-PDF"></i></button>`;
//   const view = `<button title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-eye" data-action-type="View"></i></button>`;
//    const sendMail = `<button title="SendMail" type="button" class="btn btn-icon" data-action-type="SendMail" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-paper-plane" data-action-type="SendMail"></i></button>`;
//   const spinnerIcon = `<button title="SpinnerIcon" type="button" class="btn btn-icon" data-action-type="SpinnerIcon" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-spinner fa-spin" data-action-type="SpinnerIcon"></i></button>`;
//   const reject = `<button title="Reject" type="button" class="btn btn-icon" data-action-type="Reject" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-ban" data-action-type="Reject"></i></button>`;
//   const fetchData = `<button title="FetchData" type="button" class="btn btn-icon" data-action-type="FetchData" data-toggle="modal" data-target="#viewmodal"><i class="fas fa-sync-alt" data-action-type="FetchData"></i></button>`;
//   const restore = `<button title="Restore" type="button" class="btn btn-icon" data-action-type="Restore" data-toggle="modal" data-target="#viewmodal"><i class="fas fa-redo-alt" data-action-type="Restore"></i></button>`;
// console.log("rowdata",rowData);
//   if (rowData && rowData.length > 0) {
//     rowData.forEach((object, i) => {
//       const actions = sendMailSpinner.includes(object._id)
//         ? spinnerIcon
//         : sendMail;
//       object.actions =
//         (tabType == "new" || tabType == "downloadpdf" ||tabType ==="credownload" || tabType==="landdownload"||tabType==="businessdownload"||tabType==="multifamilydownload"? createDownload : "") +
//         (object.pdf ? download : "") +
//         (tabType == "new" ? reject : "") +
//         (["reject", "business", "cre","land","multifamily"].includes(tabType) ? restore : "") +
//         view +
//         (!["reject", "business", "cre","land","multifamily"].includes(tabType) ? fetchData : "");
//     });
//   }

const download = `<button title="Download" type="button" class="btn btn-icon" data-action-type="Download" data-toggle="modal" data-target="#viewmodal"><i class="fas fa-download" data-action-type="Download"></i></button>`;
const createDownload = `<button title="Create-PDF" type="button" class="btn btn-icon" data-action-type="Create-PDF" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-plus-circle" data-action-type="Create-PDF"></i></button>`;
const sendMail = `<button title="SendMail" type="button" class="btn btn-icon" data-action-type="SendMail" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-paper-plane" data-action-type="SendMail"></i></button>`;
const spinnerIcon = `<button title="SpinnerIcon" type="button" class="btn btn-icon" data-action-type="SpinnerIcon" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-spinner fa-spin" data-action-type="SpinnerIcon"></i></button>`;
const reject = `<button title="Reject" type="button" class="btn btn-icon" data-action-type="Reject" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-ban" data-action-type="Reject"></i></button>`;
const fetchData = `<button title="FetchData" type="button" class="btn btn-icon" data-action-type="FetchData" data-toggle="modal" data-target="#viewmodal"><i class="fas fa-sync-alt" data-action-type="FetchData"></i></button>`;
const restore = `<button title="Restore" type="button" class="btn btn-icon" data-action-type="Restore" data-toggle="modal" data-target="#viewmodal"><i class="fas fa-redo-alt" data-action-type="Restore"></i></button>`;

if (rowData && rowData.length > 0) {
  rowData.forEach((object, i) => {
    const actions = sendMailSpinner.includes(object._id)
      ? spinnerIcon
      : sendMail;

    const view = `
      <button 
        title="View" 
        type="button" 
        class="btn btn-icon" 
        data-action-type="View" 
        data-toggle="modal" 
        data-target="#viewmodal"
      >
        <i class="fa fa-eye ${object.newdatastatus ===true ? 'text-blue' : ''}" data-action-type="View"></i>
      </button>
    `;

    object.actions =
      (tabType == "new" || tabType == "downloadpdf" || tabType === "credownload" || tabType === "landdownload" || tabType === "businessdownload" || tabType === "multifamilydownload" ? createDownload : "") +
      (object.pdf ? download : "") +
      (tabType == "new" ? reject : "") +
      (["reject", "business", "cre", "land", "multifamily"].includes(tabType) ? restore : "") +
      view + // Use individual view button here
      (!["reject", "business", "cre", "land", "multifamily"].includes(tabType) ? fetchData : "");
  });
}


  const cityOptions = [];
  const stateOptions = [];
  if (statelist && statelist.length > 0) {
    statelist.map((item) => {
      stateOptions.push({
        value: item.statename,
        label: item.statename,
        code: item.statecode,
        name: "state",
      });
    });
  }
  if (citylist && citylist.length > 0) {
    citylist.map((item) => {
      if (state && state.value == item.statename) {
        cityOptions.push({
          value: item.cityname,
          label: item.cityname,
          name: "city",
        });
      }
    });
  }
  if (city && city.value) {
    cityOptions.unshift({ label: "Deselect", value: "deselect", name: "city" });
  }

  
  const [columnDefs] = useState([
    {
      headerName: "_id",
      field: "_id",
      hide: true,
      valueGetter: function (params) {
        return `${params.data ? params.data._id : ""}`;
      },
    },
    {
      headerName: "Status",
      field: "status-csv",
      hide: true,
      valueGetter: function (params) {
        return "Subscribed";
      },
    },
    {
      headerName: "Label",
      field: "label-csv",
      hide: true,
      valueGetter: function (params) {
        return "Broker";
      },
    },
    // {
    //   headerName: "State",
    //   field: "state-csv",
    //   hide: true,
    //   valueGetter: function (params) {
    //     return `${params.data ? params.data.state : ""}`;
    //   },
    // },
    {
      headerName: "City",
      field: "city-csv",
      hide: true,
      valueGetter: function (params) {
        return params.data && params.data.state && params.data.state.includes(",")
          ? params.data.state.split(",")[0].trim()
          : "";
      },
    },
    {
      headerName: "State",
      field: "state-csv",
      hide: true,
      valueGetter: function (params) {
        return params.data && params.data.state && params.data.state.includes(",")
          ? params.data.state.split(",")[1].trim()
          : "";
      },
    },
    
    {
      headerName: "Broker",
      field: "name-csv",
      hide: true,
      valueGetter: function (params) {
        return `${params.data ? params.data.brokername : ""}`;
      },
    },
    {
      headerName: "Brokerage",
      field: "brokerage-csv",
      hide: true,
      valueGetter: function (params) {
        return `${params.data ? params.data.brokercompany : ""}`;
      },
    },
    {
      headerName: "Phone",
      field: "phone-csv",
      hide: true,
      valueGetter: function (params) {
        return `${
          params.data
            ? formatPhoneNumber(
                params.data.brokerphonenumber,
                params.data.brokercccode
              )
            : ""
        }`;
      },
    },
    {
      headerName: "Email",
      field: "email-csv",
      hide: true,
      valueGetter: function (params) {
        return `${params.data ? params.data.brokeremail : ""}`;
      },
    },
    {
      headerName: "Address",
      field: "address",
      sortable: false,
      floatingFilter: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      valueGetter: function (params) {
        if (params.data && params.data.address)
          return `${params.data ? params.data.address : ""}`;
        else return "---";
      },
    },
    {
      headerName: "Price",
      field: "price",
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        if (params.data && params.data.price) return Number(params.data.price);
        else return "---";
      },
    },
    {
      headerName: "Square Feet",
      field: "squarefeet",
      sortable: false,
      floatingFilter: true,
      valueGetter: function (params) {
        if (params.data && params.data.squarefeet)
          return `${params.data ? params.data.squarefeet : ""}`;
        else return "---";
      },
    },
    {
      headerName: "Property Types",
      field: "propertytypes",
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        return `${
          params.data &&
          params.data.PropertyFacts &&
          params.data.PropertyFacts.PropertyType
            ? params.data.PropertyFacts.PropertyType
            // :params.data &&
            // params.data.PropertyFacts &&
            // params.data.PropertyFacts.propertyType? params.data.PropertyFacts.propertyType
            : "---"
        }`;
      },
    },
    {
      headerName: "Name | Brokerage",
      field: "brokername",
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        if (params.data && params.data.brokername)
          return `${params.data ? params.data.brokername : ""} | ${
            params.data ? params.data.brokercompany : ""
          }`;
        else return "---";
      },
    },
    {
      headerName: "Phone | Email",
      field: "brokerphonenumber",
      sortable: false,
      floatingFilter: true,
      valueGetter: function (params) {
        if (params.data && params.data.brokerphonenumber)
          return `${params.data ? params.data.brokerphonenumber : ""} | ${
            params.data ? params.data.brokeremail : ""
          }`;
        else return "---";
      },
      cellRenderer: function (params) {
        if (params.data && params.data.brokerphonenumber)
          return `${
            params.data
              ? formatPhoneNumber(
                  params.data.brokerphonenumber,
                  params.data.brokercccode
                )
              : ""
          } | ${params.data ? params.data.brokeremail : ""}`;
        else return "---";
      },
    },
    {
      headerName: "Created Date & Time",
      field: "createdAt",
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        return params.data && params.data.createdAt
          ? `${moment(params.data.createdAt).format("MM-DD-YYYY hh:mm A")}`
          : "---";
      },
    },
    {
      headerName: "Download By",
      field: "downloadpdfuser",
      hide: true,
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        return params.data && params.data.downloadpdfuser
          ? params.data.downloadpdfuser
          : "---";
      },
    },
    {
      headerName: "Rejected By",
      field: "rejectusername",
      hide: true,
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        return params.data && params.data.rejectusername
          ? params.data.rejectusername
          : "---";
      },
    },
    {
      headerName: "Modified By",
      field: "modifiedby",
      hide: true,
      sortable: true,
      floatingFilter: true,
      valueGetter: function (params) {
        return params.data && params.data.rejectusername
          ? params.data.rejectusername
          : "---";
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      cellStyle: { "text-align": "center" },
      pinned: "right",
      filter: false,
      suppressMenu: true,
      sortable: false,
      floatingFilter: false,
      cellRenderer: function (params) {
        return <div dangerouslySetInnerHTML={{ __html: params.value }}></div>;
      },
      // tooltipValueGetter: (params) => {
      //     return (params.data && params.data.downloadpdfuser && params.data.downloadpdfuser.length > 0) ? (params.data.downloadpdfuser.map(e => e.username)).join(', ') : null;
      // }
    },
  ]);

  const onChangeTab = (type) => {
    let updatedColumnDefs = columnDefs;
    updatedColumnDefs = updatedColumnDefs.map((col) => {
      if (col.field === "rejectusername") {
        return { ...col, hide: type == "reject" ? false : true };
      }
      if (col.field === "modifiedby") {
        return {
          ...col,
          hide: type === "cre" || type === "business" ||type ==="multifamily" || type==="land" ? false : true,
        };
      }
      if (col.field === "downloadpdfuser") {
        return { ...col, hide: type == "downloadpdf"||type ==="credownload" || type==="landdownload"||type==="businessdownload"||type==="multifamilydownload" ? false : true };
      }
      return col;
    });
    gridApi.setColumnDefs(updatedColumnDefs);
    localStorage.setItem("tabType", type ? type : undefined);
    setPerPage(25);
    setCurrentPage(1);
    if (gridApiRef.current) {
      const columnApi = gridApiRef.current.columnApi;
      const columnState = columnApi.getColumnState();
      columnState.forEach((col) => {
        col.sort = null;
        col.sortIndex = null;
      });
      columnApi.applyColumnState({ state: columnState, applyOrder: true });
      gridApiRef.current.api.setFilterModel("");
      setFilter("");
      setSort("");
    }
    setTabType(type);
    onFetchData({
      tabType: type ? type : undefined,
      perPage: 25,
      currentPage: 1,
      filter: "",
      sort: "",
    });
  };

  const onFilterChanged = (event) => {
    const newFilter = event.api.getFilterModel();
    setCurrentPage(1);
    setFilter(newFilter);
    onFetchData({
      currentPage: 1,
      filter: newFilter,
    });
  };

  const onSortChanged = (event) => {
    const sortModel = getSortModelAlternative(event.columnApi);
    setCurrentPage(1);
    setSort(sortModel);
    onFetchData({
      currentPage: 1,
      sort: sortModel,
    });
  };

  const handleDownload = () => {
    if (isSearchClicked) {
     // console.log("Search button was clicked.");
      setResetFilter(true);
      setIsSearchClicked(true)
      
    } else {
      //console.log("Search button was not clicked.");
      setResetFilter(false);
      setIsSearchClicked(false);
    }
    setShowModal(true);
  };

  
  const handleOldDownload = () => {
    if (isSearchClicked) {
     // console.log("Search button was clicked.");
      setResetFilter(true);
      setIsSearchClicked(true)
      
    } else {
      //console.log("Search button was not clicked.");
      setResetFilter(false);
      setIsSearchClicked(false);
    }
    setShowOldModal(true);
  };
  const handleCloseModal = () => {
    reset();
    if (isSearchClicked) {
      //console.log("Search button was clicked.");
      setResetFilter(true);
      setIsSearchClicked(true)
      
    } else {
     // console.log("Search button was not clicked.");
      setResetFilter(false);
      setIsSearchClicked(false);
    }
    setShowModal(false);
  };
  const handleCloseOldModal = () => {
    resetOldModal();
    if (isSearchClicked) {
      //console.log("Search button was clicked.");
      setResetFilter(true);
      setIsSearchClicked(true)
      
    } else {
     // console.log("Search button was not clicked.");
      setResetFilter(false);
      setIsSearchClicked(false);
    }
    setShowOldModal(false);
  };
  const handleCloseViewModal = () => {
    setShowViewModal(false);
  };
  const onChangeStatus = (_id, ListingId, type) => {
    setShowViewModal(false);
    onFetchReject(_id, ListingId, type);
  };
  const onFetchReject = (_id, ListingId, type = "reject") => {
    let confirmationMessage;
    switch(type) {
        case 'cre':
            confirmationMessage = "Are you sure you want to mark as CRE?";
            break;
        case 'business':
            confirmationMessage = "Are you sure you want to mark as Business?";
            break;
        case 'reject':
          confirmationMessage = "Are you sure you want to mark as Reject?";
          break;
          case 'multifamily':
            confirmationMessage = "Are you sure you want to mark as Multifamily?";
            break;
            case 'land':
              confirmationMessage = "Are you sure you want to mark as Land?";
              break;
        default:
            confirmationMessage = "Are you sure you want to mark as Reject?";
            break;
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary ",
        cancelButton: "btn btn-default mr-3",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: confirmationMessage,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setListSpinner(true);
          axios
            .post(ImportedURL.API.updaterejectstatus, {
              _id: _id,
              status: true,
              type: type,
              ListingId: ListingId,
            })
            .then((res) => {
              onFetchData();
              let successMessage=""
              switch (type) {
                case 'reject':
                    successMessage = "Rejected status updated successfully";
                    break;
                case 'cre':
                    successMessage = "CRE status updated successfully";
                    break;
                case 'business':
                    successMessage = "Business status updated successfully";
                    break;
                case 'multifamily':
                      successMessage = "Multifamily status updated successfully";
                      break;
                case 'land':
                      successMessage = "Land status updated successfully";
                      break;
                default:
                    successMessage = `${type} status updated successfully`;
            }
            Success(successMessage);
              setListSpinner(false);
            })
            .catch((err) => {
              setListSpinner(false);
            });
        }
      });
  };
  const onRowClicked = async (event) => {
    const { _id, ListingId, image, address } = event.data;
    const value = event.event.target.getAttribute("data-action-type");
    if (value === "Create-PDF") {
   //   console.log("event", event.data);
      if (isSearchClicked) {
        setResetFilter(true);
        setIsSearchClicked(true)
        
      } else {
        setResetFilter(false);
        setIsSearchClicked(false);
      }
      // Mapping tabType to their corresponding PDF status fields
    const pdfStatusFieldMap = {
      credownload: "crepdfstatus",
     // cre: "crepdfstatus",
      downloadpdf: "generatorpdfstatus",
      businessdownload: "businesspdfstatus",
     // business: "businesspdfstatus",
      multifamilydownload: "multifamilypdfstatus",
    // multifamily: "multifamilypdfstatus",
      landdownload: "landpdfstatus",
    //  land: "landpdfstatus",
    };
  
    const pdfStatusField = pdfStatusFieldMap[tabType];
  
    // if (!pdfStatusField) {
    //   console.error("Invalid tabType");
    //   return;
    // }
   // Check if the single object has an empty SBA object
   const hasEmptySba = 
   event.data.SBA &&                      // Check if SBA exists
   typeof event.data.SBA === "object" &&  // Ensure it's an object
   Object.keys(event.data.SBA).length === 0 && // Check if it's empty
   event.data[pdfStatusField] === true;   // Ensure the status field is true
 
// console.log("SBA Field:", event.data.SBA); // Log the SBA field
 //console.log("Is SBA an object and empty:", hasEmptySba); // Log condition result
 
 if (hasEmptySba) {
  // console.log("The SBA field is an empty object in the current row");
   const ids = {
     _id: event.data._id,
     image: event.data.image,
     address: event.data.address,
   };
   setListingId([ids]);
   handleOldDownload();
   return;
 }
 
      setListingId([{ _id: _id, image: image, address: address }]);
      handleDownload();
      setSelectedRows([]);
      if (gridApiRef.current) {
        const selectedNodes = gridApiRef.current.api.getSelectedNodes();
        selectedNodes.forEach((node) => node.setSelected(false));
      }
    }
    if (value === "View") {
      setViewData(event.data);
      setShowViewModal(true);
    }
    if (value === "Download") {
      if (isSearchClicked) {
       // console.log("Search button was clicked.");
        setResetFilter(true);
        setIsSearchClicked(true)
        
      } else {
      //  console.log("Search button was not clicked.");
        setResetFilter(false);
        setIsSearchClicked(false);
      }
      let base64 = await getImageAsBase64(image);
     // console.log("event",event);

      if (event.data.SBA && Object.keys(event.data.SBA).length === 0) {
      //  console.log("SBA is an empty object");
        const formData = {
          id: event.data._id ? event.data._id : null,
          downpayment: event.data.downpayment ? event.data.downpayment : "",
          financelead: financelead ? financelead : "",
          termofloan: event.data.termofloan ? event.data.termofloan : "",
          additionalcost: event.data.additionalcost
            ? event.data.additionalcost
            : "",
          interestpercentage: event.data.interestpercentage
            ? event.data.interestpercentage
            : "",
          alreadexists: true,
          imageBase64: base64,
          tabType:tabType
        };
       // console.log("formData",formData);

        setListSpinner(true);
      axios
        .post(ImportedURL.API.downloadPdfwithoutFinancecost, formData, { responseType: "blob" })
        .then((res) => {
          const getData = rowData.find((e) => e._id == formData.id); //here listingId is a variable that 
          //store id not the listing id
          let formattedAddress = replaceLoopnetPDFName(getData.address);
          const blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = formattedAddress + ".pdf";
          link.click();
          link.remove();
          window.URL.revokeObjectURL(link.href);
          Success("PDF download successfully");
          setListSpinner(false);
        })
        .catch((err) => {
          setListSpinner(false);
        });
      }
      else{
          const formData = {
          id: event.data._id ? event.data._id : null, // this variable is used to get id not listingId
          financelead: event.data.financelead ? event.data.financelead : '',
          alreadexists: true,
          imageBase64: base64,
          tabType: tabType,
          rowiconclick:true
        };
        
        // Dynamically add tab data
        jsonData.forEach(tab => {
          const tabName = tab.name; // Get the tab name (e.g., "SBA", "CMBS", "Conventional")
          formData[tabName] = {
            downpayment: Number(event.data[tabName]?.downpayment || 0),
            termofloan: Number(event.data[tabName]?.termofloan || 0),
            interestpercentage: Number(event.data[tabName]?.interestpercentage || 0),
            financial_cost: event.data[tabName]?.financial_cost || '',
            
          };
        });
        
        setListSpinner(true);
      axios
        .post(ImportedURL.API.downloadPdf, formData, { responseType: "blob" })
        .then((res) => {
          const getData = rowData.find((e) => e._id == formData.id); //here listingId is a variable that 
          //store id not the listing id
          let formattedAddress = replaceLoopnetPDFName(getData.address);
          const blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = formattedAddress + ".pdf";
          link.click();
          link.remove();
          window.URL.revokeObjectURL(link.href);
          Success("PDF download successfully");
          setListSpinner(false);
        })
        .catch((err) => {
          setListSpinner(false);
        });  
         
      }  

    }
    if (value == "SendMail") {
      setListSpinner(true);
      setSendMailSpinner((prevState) => [...prevState, _id]);
      let base64 = await getImageAsBase64(image);
      const formData = {
        id: event.data._id ? event.data._id : null,
        downpayment: event.data.downpayment ? event.data.downpayment : "",
        financelead: financelead ? financelead : "",
        termofloan: event.data.termofloan ? event.data.termofloan : "",
        additionalcost: event.data.additionalcost
          ? event.data.additionalcost
          : "",
        interestpercentage: event.data.interestpercentage
          ? event.data.interestpercentage
          : "",
        imageBase64: base64,
      };
      axios
        .post(ImportedURL.API.sendmailwithpdf, formData)
        .then((res) => {
          setSendMailSpinner((prevState) => prevState.filter((e) => e !== _id));
          Success("Send email successfully");
          setListSpinner(false);
        })
        .catch((err) => {
          setSendMailSpinner((prevState) => prevState.filter((e) => e !== _id));
          setListSpinner(false);
        });
    }
    if (value == "Reject") {
      onFetchReject(_id, ListingId);
    }
    if (value == "FetchData") {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary ",
          cancelButton: "btn btn-default mr-3",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you fetching the data again?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            setListSpinner(true);
            axios
              .post(ImportedURL.API.updateloopnetdata, {
                _id: _id,
                ListingId: ListingId,
              })
              .then((res) => {
                onFetchData();
                Success("Data fetched successfully");
                setListSpinner(false);
              })
              .catch(({ response }) => {
                const { message } = response.data ? response.data : {};
                Error(message);
                setListSpinner(false);
              });
          }
        });
    }
    if (value == "Restore") {
      let alertTilte = "";
      if (tabType == "reject") {
        alertTilte = "Are you sure you want to unmark from Rejected?";
      }
      else if (tabType == "cre") {
        alertTilte = "Are you sure you want to unmark from CRE?";
      } 
      else if (tabType == "business") {
        alertTilte = "Are you sure you want to unmark from Business?";
     }
     else if (tabType == "multifamily") {
      alertTilte = "Are you sure you want to unmark from Multifamily?";
   }
   else if (tabType == "land") {
    alertTilte = "Are you sure you want to unmark from Land?";
  }
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary ",
          cancelButton: "btn btn-default mr-3",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: alertTilte,
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            setListSpinner(true);
            axios
              .post(ImportedURL.API.updaterejectstatus, {
                _id: _id,
                status: false,
                ListingId: ListingId,
                type: tabType,
              })
              .then((res) => {
                onFetchData();
                let successMessage = "";
                switch (tabType) {
                    case 'reject':
                        successMessage = "Rejected status updated successfully";
                        break;
                    case 'cre':
                        successMessage = "CRE status updated successfully";
                        break;
                    case 'business':
                        successMessage = "Business status updated successfully";
                        break;
                    case 'multifamily':
                          successMessage = "Multifamily status updated successfully";
                          break;
                    case 'land':
                            successMessage = "Land status updated successfully";
                            break;
                    default:
                        successMessage = `${tabType} status updated successfully`;
                }
                Success(successMessage);

                setListSpinner(false);
              })
              .catch((err) => {
                setListSpinner(false);
              });
          }
        });
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedRows(selectedRows);
  };

  const onPagination = (page) => {
    setCurrentPage(page);
    onFetchData({ currentPage: page });
  };

  const onPerPageChange = (num) => {
    setCurrentPage(1);
    setPerPage(num);
    onFetchData({ currentPage: 1, perPage: num });
  };

  // const downloadPdf = () => {
  //   console.log("selectedRows",selectedRows);
  //   let ids = selectedRows.map((item) => ({
  //     _id: item._id,
  //     image: item.image,
  //     address: item.address,
  //   }));
  //  // console.log(ids);
  //   setListingId(ids);
  //   handleDownload();
  // };


//   const downloadPdf = () => {
//     console.log("selectedRows", selectedRows); 
  
//    // Check if any selected row has an empty SBA object
// //let hasEmptySba = false;

// let hasEmptySba = false;

// if (tabType === 'credownload') {
//   hasEmptySba = selectedRows.some(rowdata => 
//     rowdata.SBA && Object.keys(rowdata.SBA).length === 0 && rowdata.crepdfstatus === true
//   );
// } else if (tabType === 'downloadpdf') {
//   hasEmptySba = selectedRows.some(rowdata => 
//     rowdata.SBA && Object.keys(rowdata.SBA).length === 0 && rowdata.generatorpdfstatus === true
//   );
// } else if (tabType === 'businessdownload') {
//   hasEmptySba = selectedRows.some(rowdata => 
//     rowdata.SBA && Object.keys(rowdata.SBA).length === 0 && rowdata.businesspdfstatus === true
//   );
// } else if (tabType === 'multifamilydownload') {
//   hasEmptySba = selectedRows.some(rowdata => 
//     rowdata.SBA && Object.keys(rowdata.SBA).length === 0 && rowdata.multifamilypdfstatus === true
//   );
// } else if (tabType === 'landdownload') {
//   hasEmptySba = selectedRows.some(rowdata => 
//     rowdata.SBA && Object.keys(rowdata.SBA).length === 0 && rowdata.landpdfstatus === true
//   );
// }

// // If an empty SBA object is found, display an error and stop further processing
// if (hasEmptySba) {
//   Error("There are selected rows with both old and new data, or possibly only old data. Cannot proceed with the download.");
//   return;
// }
// else{
//   // If all selected rows have a valid SBA object, proceed
//     let ids = selectedRows.map((item) => ({
//       _id: item._id,
//       image: item.image,
//       address: item.address,
//     }));

//     setListingId(ids);
//     handleDownload();
//   }

// let hasAllEmptySba = false;

// if (tabType === 'credownload') {
//   hasAllEmptySba = selectedRows.every(rowdata => 
//     rowdata.SBA && Object.keys(rowdata.SBA).length === 0 && rowdata.crepdfstatus === true
//   );
// } else if (tabType === 'downloadpdf') {
//   hasAllEmptySba = selectedRows.every(rowdata => 
//     rowdata.SBA && Object.keys(rowdata.SBA).length === 0 && rowdata.generatorpdfstatus === true
//   );
// } else if (tabType === 'businessdownload') {
//   hasAllEmptySba = selectedRows.every(rowdata => 
//     rowdata.SBA && Object.keys(rowdata.SBA).length === 0 && rowdata.businesspdfstatus === true
//   );
// } else if (tabType === 'multifamilydownload') {
//   hasAllEmptySba = selectedRows.every(rowdata => 
//     rowdata.SBA && Object.keys(rowdata.SBA).length === 0 && rowdata.multifamilypdfstatus === true
//   );
// } else if (tabType === 'landdownload') {
//   hasAllEmptySba = selectedRows.every(rowdata => 
//     rowdata.SBA && Object.keys(rowdata.SBA).length === 0 && rowdata.landpdfstatus === true
//   );
// }

// // If an empty SBA object is found, display an error and stop further processing
// if (hasAllEmptySba) {
//   Error("open modal");
//   return;
// }

  
    
//   };

const downloadPdf = () => {
  //console.log("selectedRows", selectedRows);
  if (isSearchClicked) {
    //  console.log("Search button was clicked.");
      setResetFilter(true);
      setIsSearchClicked(true)
      
    } else {
     // console.log("Search button was not clicked.");
      setResetFilter(false);
      setIsSearchClicked(false);
    }

  // Mapping tabType to their corresponding PDF status fields
  const pdfStatusFieldMap = {
    credownload: "crepdfstatus",
    //cre: "crepdfstatus",
    downloadpdf: "generatorpdfstatus",
   // new: "generatorpdfstatus",
    businessdownload: "businesspdfstatus",
   // business: "businesspdfstatus",
    multifamilydownload: "multifamilypdfstatus",
    //multifamily: "multifamilypdfstatus",
    landdownload: "landpdfstatus",
   //land: "landpdfstatus",
  };

  const pdfStatusField = pdfStatusFieldMap[tabType];

  // if (!pdfStatusField) {
  //   console.error("Invalid tabType");
  //   return;
  // }

  // Check if all selected rows have an empty SBA object
  const hasAllEmptySba = selectedRows.every(
    (rowdata) =>
      rowdata.SBA &&
      Object.keys(rowdata.SBA).length === 0 &&
      rowdata[pdfStatusField] === true
  );

  if (hasAllEmptySba) {
   // console.log("All are empty");
    // Additional logic when all rows have an empty SBA
    const ids = selectedRows.map((item) => ({
      _id: item._id,
      image: item.image,
      address: item.address,
    }));
  
    setListingId(ids);
    handleOldDownload();
    return;
  }

  // Check if some selected rows have an empty SBA object
  const hasEmptySba = selectedRows.some(
    (rowdata) =>
      rowdata.SBA &&
      Object.keys(rowdata.SBA).length === 0 &&
      rowdata[pdfStatusField] === true
  );

  if (hasEmptySba) {
    Error(
      "There are selected rows with both old and new data. Cannot proceed with the download."
    );
    return;
  }

  // If all validations pass, proceed with downloading
  const ids = selectedRows.map((item) => ({
    _id: item._id,
    image: item.image,
    address: item.address,
  }));

  setListingId(ids);
  handleDownload();
}; 

  const getRowStyle = (params) => {
    if (params.data && params.data.generatorcsvstatus) {
      return { backgroundColor: "#d7d7d7" }; // Your desired style
    }
  };

  const exportToCSV = () => {
    if (!gridApi) return;
    setListSpinner(true);

    const selectedRows = gridApi.getSelectedRows();
    const isSelected = selectedRows && selectedRows.length > 0;
    const firstRow = gridApi.getFirstDisplayedRow();
    const lastRow = gridApi.getLastDisplayedRow();

    let ids = [];
    let listingIds = [];
    if (isSelected) {
      selectedRows.map((row) => {
        ids.push(row._id);
        listingIds.push(row.ListingId);
      });
    } else {
      gridApi.forEachNodeAfterFilterAndSort((node) => {
        ids.push(node.data._id);
        listingIds.push(node.data.ListingId);
      });
    }
    axios
      .post(ImportedURL.API.updatedownloadcsvstatus, {
        ids: ids,
        listingIds: listingIds,
      })
      .then((res) => {
        onFetchData();
        gridApi.exportDataAsCsv({
          columnKeys: [
            "status-csv",
            "label-csv",
            "city-csv",  // Check that these match your grid configuration IDs
            "state-csv",
            "name-csv",
            "brokerage-csv",
            "phone-csv",
            "email-csv",
          ],       
          fileName: loopnetCSVName() + ".csv",
          shouldRowBeSkipped: (params) => {
            return isSelected
              ? !selectedRows.includes(params.node.data)
              : params.node.rowIndex < firstRow ||
                  params.node.rowIndex > lastRow;
          },
          processCellCallback: function (params) {
            return params.value;
          },
            
        });
        if (isSelected) {
          setSelectedRows([]);
          if (gridApiRef.current) {
            const selectedNodes = gridApiRef.current.api.getSelectedNodes();
            selectedNodes.forEach((node) => node.setSelected(false));
          }
        }
        setListSpinner(false);
      })
      .catch((err) => {
        setListSpinner(false);
      });
  };

  
  const onChange = (event) => {
    const { value, name } = event.target;
    const number = value
      .replace(/[^0-9.]/g, "")
      .replace(/^(\d+\.\d{0,2})\d*$/, "$1");
    const minmax = value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d+\.\d{0,2})\d*$/, "$1");
    if (name == "downpayment") {
      setDownPayment(number);
      setDownPaymentError(false);
    }
    if (name == "termofloan") {
      setTermOfLoan(number);
      setTermOfLoanError(false);
    }
    if (name == "interestpercentage") {
      setInterestPercentage(number);
      setInterestPercentageError(false);
    }
    if (name == "additionalcost") {
      setAdditionalCosts(number);
    }
    if (name == "maxPrice") {
      setMaxPrice(minmax);
      if (minmax) setResetFilter(true);
    }
    if (name == "minPrice") {
      setMinPrice(minmax);
      if (minmax) setResetFilter(true);
    }
  };

  const onChangeSelect = (event) => {
    const { name, value, selectAll } = event;
    setResetFilter(true);
    if (name == "propertUse") {
      setPropertUse(event);
    }
    if (name == "propertType") {
      setPropertTypeSelectAll(selectAll);
      setPropertType(value ? value : []);
    }
    if (name == "state") {
      setState(event);
      setCity("");
    }
    if (name == "city") {
      if (value && value == "deselect") {
        setCity("");
      } else {
        setCity(event);
      }
    }
    if (name === "financelead") {
      if (value === "Deselect") {  // Ensure correct comparison for 'Deselect' (case-sensitive)
         setfinancelead("");
        setFinancialError(true);   // Show the error when 'Deselect' is selected
      } else if (value && value !== "Deselect") {
       setfinancelead(event);     // Set the selected value
        setFinancialError(false);  // Clear the error
      }
    }
  };
  // Function to check if a tab has valid data
  // const isValidTabData = (section) => {
  //   // Check if any of the required fields are non-empty
  //   return (
  //     (section.downpayment && section.downpayment.trim() !== "") ||
  //     (section.termofloan && section.termofloan.trim() !== "") ||
  //     (section.interestpercentage && section.interestpercentage.trim() !== "")
  //   );
  // };
  // Function to validate if at least one tab has data
  // const validateTabData = (data, jsonData) => {
  //   let isValid = false;
  // console.log("data",data);
  //   // Loop through the dynamic tabs from jsonData
  //   jsonData.forEach((tab) => {
  //     const tabName = tab.name;
  //     if (data[tabName] && isValidTabData(data[tabName])) {
  //       isValid = true; // At least one tab is valid
  //     }
  //   });
  
  //   if (!isValid) {
  //     Error("At least one tab must have data for downpayment, termofloan, or interestpercentage.");
  //     return false;
  //   }
  //   return true;
  // };

  const handleSubmit = async () => {

    if (isSearchClicked) {
      setResetFilter(true);
      setIsSearchClicked(true);
    } else {
      setResetFilter(false);
      setIsSearchClicked(false);
    }
    // if (!validateFieldsAtleastOnedata()) {
    //   return; // Stop if validation fails
    // }
  // if (!validateFieldsdata()) {
  //   return; // Stop if validation fails
  // }


  
    if (listingId && listingId.length === 1) {
      setSaving(true);
      let base64 = await getImageAsBase64(
        listingId[0]?.image || null
      );
  
      // Retrieve and parse data from localStorage
      const storedData = localStorage.getItem("tabData");
      
      if (storedData) {
        // const isValid = validateTabData(storedData, jsonData);
        // console.log("isValid",isValid);
        // if (!isValid) {
        //   // Handle the case when validation fails         
        //   Error("Please fill in the required fields in at least one tab.");
        //   setShowModal(false);
        //   setSaving(false);
        //   return
        // }
        
        const parsedData = JSON.parse(storedData);
 // console.log("parsedData",parsedData);

        // const formatData = (data) => {
        //   const formattedData = {};
        //   Object.keys(data).forEach((key) => {
        //     const section = data[key];
        //     // Check if none of the required fields are selected
        //     console.log("section",section);
        //     const financeRows = section.financeRows.reduce((acc, row) => {
        //       if (row.selectValue) {
        //         acc[row.selectValue.value] = row.textValue;
        //       }
        //       return acc;
        //     }, {});       
        //       formattedData[key] = {
        //       downpayment: section.downpayment,
        //       termofloan: section.termofloan,
        //       interestpercentage: section.interestpercentage,
        //       financial_cost: financeRows,
        //     }; 
          
        //   });  
        //  // formattedData.financelead = data[jsonData[0].name][jsonData[0].name].financelead.value;
        //   formattedData.financelead = financeLead.value;
        //   formattedData.id = listingId[0]?._id || null;
        //   formattedData.tabType = tabType;
        //   formattedData.imageBase64= base64;
        //   return formattedData;
        // };
        
        // const formatData = (data) => {
        //   const formattedData = {};
        //   Object.keys(data).forEach((key) => {
        //     const section = data[key];
        
        //     // Validation: Check if any of the three required fields are missing
        //     const isPartiallyFilled =
        //       !section.downpayment || !section.termofloan || !section.interestpercentage;
        
        //     // Reset all fields to empty if partially filled
        //     const downpayment = isPartiallyFilled ? "" : section.downpayment;
        //     const termofloan = isPartiallyFilled ? "" : section.termofloan;
        //     const interestpercentage = isPartiallyFilled ? "" : section.interestpercentage;
        
        //     // Process financeRows
        //     const financeRows = section.financeRows.reduce((acc, row) => {
        //       if (row.selectValue) {
        //         acc[row.selectValue.value] = row.textValue;
        //       }
        //       return acc;
        //     }, {});
        
        //     formattedData[key] = {
        //       downpayment,
        //       termofloan,
        //       interestpercentage,
        //       financial_cost: financeRows,
        //     };
        //   });
        
        //   // Add additional fields
        //   formattedData.financelead = financeLead.value;
        //   formattedData.id = listingId[0]?._id || null;
        //   formattedData.tabType = tabType;
        //   formattedData.imageBase64 = base64;
        
        //   return formattedData;
        // };


        const formatData = (data) => {
          const formattedData = {};
        
          Object.keys(data).forEach((key) => {
            const section = data[key];
        
            // Check if all required fields are filled
            const isFullyFilled =
              section.downpayment?.toString().trim() &&
              section.termofloan?.toString().trim() &&
              section.interestpercentage?.toString().trim();
        
            // Only assign data if all fields are fully filled
            const downpayment = isFullyFilled ? section.downpayment : "";
            const termofloan = isFullyFilled ? section.termofloan : "";
            const interestpercentage = isFullyFilled ? section.interestpercentage : "";
        
            // Process financeRows only when all fields are filled
            const financeRows = isFullyFilled
              ? section.financeRows.reduce((acc, row) => {
                  if (row.selectValue) {
                    acc[row.selectValue.value] = row.textValue;
                  }
                  return acc;
                }, {})
              : {}; // Empty if not fully filled
        
            formattedData[key] = {
              downpayment,
              termofloan,
              interestpercentage,
              financial_cost: financeRows, // Include only if fully filled
            };
          });
        
          // Add additional fields
          formattedData.financelead = financeLead?.value || "";
          formattedData.id = listingId[0]?._id || null;
          formattedData.tabType = tabType;
          formattedData.imageBase64 = base64;
        
          return formattedData;
        };
        
        
        const formattedData = formatData(parsedData);

       // console.log("formattedData",formattedData);
        
        try {
          const res = await axios.post(ImportedURL.API.downloadPdf, formattedData, { responseType: "blob" });
          if (!res.data || res.data.size === 0) {
            Error("No PDF data received from the server.");
            setSaving(false);
            return;
          }  
         // console.log("PDF response:", res);
          const getData = rowData.find((e) => e._id == formattedData.id);
          let formattedAddress = replaceLoopnetPDFName(getData.address);
          const blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${formattedAddress}.pdf`;
          link.click();
          link.remove();
          window.URL.revokeObjectURL(link.href);
          onFetchData();
          Success("PDF downloaded successfully");
          reset();
          setShowModal(false);
          setSaving(false);
          clearTabData();
        } catch (error) {
          const { message } = error.response?.data || {};
          console.log("message",message);
          Error(message);
          setSaving(false);
        }
      }
    } 
   

    else {
     // console.log("comes in else part");
      reset();
      setShowModal(false);
      setSelectedRows([]);
     // console.log("gridApiRef.current",gridApiRef.current);
    
      if (gridApiRef.current) {
        const selectedNodes = gridApiRef.current.api.getSelectedNodes();
        selectedNodes.forEach((node) => node.setSelected(false));
      }
    
      setShowProgressBar(true);
      setProgressbar({ value: 0, max: listingId.length });
    
      const downloadPdf = async (item, index) => {
        let base64 = await getImageAsBase64(item.image);
        const storedData = localStorage.getItem("tabData");
    
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          // const formatData = (data) => {
          //   const formattedData = {};
          //   Object.keys(data).forEach((key) => {
          //     const section = data[key];
          //     const financeRows = section.financeRows.reduce((acc, row) => {
          //       if (row.selectValue) {
          //         acc[row.selectValue.value] = row.textValue;
          //       }
          //       return acc;
          //     }, {});
    
          //     formattedData[key] = {
          //       downpayment: section.downpayment,
          //       termofloan: section.termofloan,
          //       interestpercentage: section.interestpercentage,
          //       financial_cost: financeRows,
          //     };
          //   });
    
          //   formattedData.financelead = data[jsonData[0].name][jsonData[0].name].financelead.value;
          //   formattedData.id = item._id || null;
          //   formattedData.tabType = tabType;
          //   formattedData.imageBase64 = base64;
           
          //   return formattedData;
          // };
          //console.log("formatData",formatData);
          // const formatData = (data) => {
          //   const formattedData = {};
          //   Object.keys(data).forEach((key) => {
          //     const section = data[key];
          
          //     // Validation: Check if any of the three required fields are missing
          //     const isPartiallyFilled =
          //       !section.downpayment || !section.termofloan || !section.interestpercentage;
          
          //     // Reset all fields to empty if partially filled
          //     const downpayment = isPartiallyFilled ? "" : section.downpayment;
          //     const termofloan = isPartiallyFilled ? "" : section.termofloan;
          //     const interestpercentage = isPartiallyFilled ? "" : section.interestpercentage;
          
          //     // Process financeRows
          //     const financeRows = section.financeRows.reduce((acc, row) => {
          //       if (row.selectValue) {
          //         acc[row.selectValue.value] = row.textValue;
          //       }
          //       return acc;
          //     }, {});
          
          //     formattedData[key] = {
          //       downpayment,
          //       termofloan,
          //       interestpercentage,
          //       financial_cost: financeRows,
          //     };
          //   });
          
          //   // Add additional fields
          //   formattedData.financelead = financeLead.value;
          //   formattedData.id = item._id || null;
          //   formattedData.tabType = tabType;
          //   formattedData.imageBase64 = base64;
          
          //   return formattedData;
          // };


          const formatData = (data) => {
            const formattedData = {};
          
            Object.keys(data).forEach((key) => {
              const section = data[key];
          
              // Check if all required fields are filled
              const isFullyFilled =
                section.downpayment?.toString().trim() &&
                section.termofloan?.toString().trim() &&
                section.interestpercentage?.toString().trim();
          
              // Only assign data if all fields are fully filled
              const downpayment = isFullyFilled ? section.downpayment : "";
              const termofloan = isFullyFilled ? section.termofloan : "";
              const interestpercentage = isFullyFilled ? section.interestpercentage : "";
          
              // Process financeRows only when all fields are filled
              const financeRows = isFullyFilled
                ? section.financeRows.reduce((acc, row) => {
                    if (row.selectValue) {
                      acc[row.selectValue.value] = row.textValue;
                    }
                    return acc;
                  }, {})
                : {}; // Empty if not fully filled
          
              formattedData[key] = {
                downpayment,
                termofloan,
                interestpercentage,
                financial_cost: financeRows, // Include only if fully filled
              };
            });
          
            // Add additional fields
            formattedData.financelead = financeLead?.value || "";
            formattedData.id = item._id || null;
            formattedData.tabType = tabType;
            formattedData.imageBase64 = base64;
          
            return formattedData;
          };
          
          try {
            const res = await axios.post(ImportedURL.API.downloadPdf, formatData(parsedData), { responseType: "blob" });
            let formattedAddress = replaceLoopnetPDFName(item.address);
            const blob = new Blob([res.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = formattedAddress + ".pdf";
            link.click();
            link.remove();
            window.URL.revokeObjectURL(link.href);
            setProgressbar((prev) => ({
              value: prev.value + 1,
              max: prev.max,
            }));
          } catch (err) {
            console.error("Error downloading PDF for item:", item, err);
          }
        }
      };
    
      const downloadAllPdfs = async () => {
        for (let i = 0; i < listingId.length; i++) {
          await downloadPdf(listingId[i], i);
        }
        Success("PDFs downloaded successfully");
        setTimeout(() => {
          let currentTabType = localStorage.getItem("tabType");
          setShowProgressBar(false);
          onFetchData({
            tabType: currentTabType ? currentTabType : tabType,
          });
        }, 2000);
      };
    
      downloadAllPdfs();
    }
    
    
  };
  
  const clearTabData = () => {
    localStorage.removeItem("tabData");
   // console.log("tabData cleared from localStorage");
  };

  const handleOldModalSubmit = async () => {
    if (isSearchClicked) {
     // console.log("Search button was clicked.");
      setResetFilter(true);
      setIsSearchClicked(true)
      
    } else {
     // console.log("Search button was not clicked.");
      setResetFilter(false);
      setIsSearchClicked(false);
    }
    let valid = 1;
    if (!downpayment) {
      setDownPaymentError(true);
      valid = 0;
    }
    if (!termofloan) {
      setTermOfLoanError(true);
      valid = 0;
    }
    if (!financelead) {
      setFinancialError(true);
      valid = 0;
    }
    if (!interestpercentage) {
      setInterestPercentageError(true);
      valid = 0;
    }
    if (valid) {
      if (listingId && listingId.length === 1) {
        setSaving(true);
        let base64 = await getImageAsBase64(
          listingId[0] && listingId[0].image ? listingId[0].image : null
        );
        const formData = {
          id: listingId[0] && listingId[0]._id ? listingId[0]._id : null,
          downpayment: downpayment ? downpayment.trim() : "",
          financelead: financelead ? financelead : "",
          termofloan: termofloan ? termofloan.trim() : "",
          interestpercentage: interestpercentage
            ? interestpercentage.trim()
            : "",
          additionalcost: additionalcost ? additionalcost.trim() : "",
          imageBase64: base64,
         tabType:tabType
        };
        axios
          .post(ImportedURL.API.downloadPdfOldStructure, formData, { responseType: "blob" })
          .then((res) => {
            const getData = rowData.find((e) => e._id == formData.id);
            let formattedAddress = replaceLoopnetPDFName(getData.address);
            const blob = new Blob([res.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = formattedAddress + ".pdf";
            link.click();
            link.remove();
            window.URL.revokeObjectURL(link.href);
            onFetchData();
            Success("PDF download successfully");
            resetOldModal();
            setShowOldModal(false);
            setSaving(false);
          })
          .catch(({ response }) => {
            console.log(response);
            const { message } = response.data ? response.data : {};
            Error(message);
            setSaving(false);
          });
      } else {
        // reset();
        resetOldModal();
        setShowOldModal(false);
        setSelectedRows([]);
        if (gridApiRef.current) {
          const selectedNodes = gridApiRef.current.api.getSelectedNodes();
          selectedNodes.forEach((node) => node.setSelected(false));
        }
        setShowProgressBar(true);
        setProgressbar({ value: 0, max: listingId.length });

        const downloadPdf = async (item, index) => {
          let base64 = await getImageAsBase64(item.image);
          const formData = {
            id: item._id || null,
            downpayment: downpayment ? downpayment.trim() : "",
            financelead: financelead ? financelead : "",
            termofloan: termofloan ? termofloan.trim() : "",
            interestpercentage: interestpercentage
              ? interestpercentage.trim()
              : "",
            additionalcost: additionalcost ? additionalcost.trim() : "",
            imageBase64: base64,
            tabType:tabType
          };

          try {
            const res = await axios.post(
              ImportedURL.API.downloadPdfOldStructure,
              formData,
              { responseType: "blob" }
            );
            let formattedAddress = replaceLoopnetPDFName(item.address);
            const blob = new Blob([res.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = formattedAddress + ".pdf";
            link.click();
            link.remove();
            window.URL.revokeObjectURL(link.href);
            setProgressbar((prev) => ({
              value: prev.value + 1,
              max: prev.max,
            }));
          } catch (err) {}
        };
        const downloadAllPdfs = async () => {
          for (let i = 0; i < listingId.length; i++) {
            await downloadPdf(listingId[i], i);
          }
          Success("PDFs downloaded successfully");
          setTimeout(() => {
            let currentTabType = localStorage.getItem("tabType");
            setShowProgressBar(false);
            onFetchData({
              tabType: currentTabType ? currentTabType : tabType,
            });
          }, 2000);
        };
        downloadAllPdfs();
      }
    }
  };

  const resetOldModal = () => {
    setDownPayment("");
    setTermOfLoan("25");
    setAdditionalCosts("0");
    setInterestPercentage("11");
    setDownPaymentError(false);
    setTermOfLoanError(false);
    setInterestPercentageError(false);
    setfinancelead("");
    setFinancialError(false);
   
  };
 
  const reset = () => {
    // Reset individual state variables
    setDownPayment("");
    setTermOfLoan("");
    setAdditionalCosts("0");
    setInterestPercentage("");
    setDownPaymentError(false);
    setTermOfLoanError(false);
    setInterestPercentageError(false);
    setfinancelead(false);
    setFinancialError(false);
  
    setTabData(initialTabData);  
    // Reset the active tab to the first tab
    setActiveTab(jsonData[0].name); // Use setActiveTab instead of activeTab
    setFinanceLead("");
  };  

  const EmptyFilter = () => {
   // console.log("empty filter is called");
    setPropertType([]);
    setPropertUse("");
    setMinPrice("500000");
    setMaxPrice("5000000");
    setIsSearchClicked(false);
    setState("");
    setCity("");
    setResetFilter(false);
    setInitialTriggle(false);
    setCurrentPage(1);
    onFetchData({
      currentPage: 1,
      propertType: [],
      propertUse: "",
      state: "",
      statecode: "",
      city: "",
      minPrice: undefined,
      maxPrice: undefined,
    });
  };

  const onSearch = () => {
    setIsSearchClicked(true); // Track that the search button was clicked
    setResetFilter(true);
    setInitialTriggle(true);
    setCurrentPage(1);
    onFetchData({
      currentPage: 1,
      minPrice: minPrice ? minPrice : undefined,
      maxPrice: maxPrice ? maxPrice : undefined,
    });
  };
const onFetchFincncialCost = () => {
  axios
    .get(ImportedURL.API.getFinancialcost)
    .then((res) => {
      const data = res.data.data;
    //  console.log("getFinancialcost", data);

      const options = data.map(item => ({
        value: item._id,
        label: item.name
      }));

      setFinancialOptions(options); // Update state
    })
    .catch((err) => {
      console.error("Error fetching financial cost:", err);
    });
};

const saveTabData = useCallback((updatedState) => {
  setTabData((prev) => (updatedState ? {
    ...prev,
    [activeTab]: updatedState,
  } : prev));
  localStorage.setItem("tabData", JSON.stringify({ ...tabData, [activeTab]: updatedState }));
}, [activeTab, tabData]);
// const handleAddRow = () => {
//   setFinanceRows([...currentTabState.financeRows, { selectValue: null, textValue: "0" }]);
// };
const handleAddRow = () => {
  const updatedFinanceRows = [...currentTabState.financeRows, { selectValue: null, textValue: "" }];
  const updatedState = { ...currentTabState, financeRows: updatedFinanceRows };
  setCurrentTabState(updatedState);
  saveTabData(updatedState);
};
// const handleRemoveRow = (index) => {
//   const updatedRows = financeRows.filter((_, i) => i !== index);
//   setFinanceRows(updatedRows);
// };

const handleRemoveRow = (index) => {
  const updatedFinanceRows = currentTabState.financeRows.filter((_, i) => i !== index);
  const updatedState = { ...currentTabState, financeRows: updatedFinanceRows };
  setCurrentTabState(updatedState);
  saveTabData(updatedState);
};


// const handleInputChange = (e) => {
//   const { name, value } = e.target;
//   setCurrentTabState((prevState) => {
//     const updatedState = { ...prevState, [name]: value };
//     saveTabData(updatedState);
//     return updatedState;
//   });
// };

// const handleInputChange = useCallback((e) => {
//   const { name, value } = e.target;
//   setCurrentTabState(prevState => {
//     const updatedState = { ...prevState, [name]: value };
//     saveTabData(updatedState); // Only save if there's a meaningful change
//     return updatedState;
//   });
// }, [saveTabData]);

const handleInputChange = useCallback(
  (e) => {
    
    const { name, value } = e.target;

    // Common numeric validation logic
    const number = value
      .replace(/[^0-9.]/g, "")
      .replace(/^(\d+\.\d{0,2})\d*$/, "$1");
    const minmax = value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d+\.\d{0,2})\d*$/, "$1");

    setCurrentTabState((prevState) => {
      let updatedState = { ...prevState };

      // Handle specific field updates with validation
      switch (name) {
        case "downpayment":
          updatedState[name] = number;
          setDownPaymentError(false);
          break;
        case "termofloan":
          updatedState[name] = number;
          setTermOfLoanError(false);
          break;
        case "interestpercentage":
          updatedState[name] = number;
          setInterestPercentageError(false);
          break;
        case "additionalcost":
          updatedState[name] = number;
          break;
        case "maxPrice":
          updatedState[name] = minmax;
          if (minmax) setResetFilter(true);
          break;
        case "minPrice":
          updatedState[name] = minmax;
          if (minmax) setResetFilter(true);
          break;
        default:
          // For all other fields, directly assign the value
          updatedState[name] = value;
          break;
      }

      // Save updated state to local storage
      saveTabData(updatedState);

      return updatedState;
    });
  },
  [saveTabData]
);



// const handleSelectChange = (value, index) => {
//   const updatedFinanceRows = [...currentTabState.financeRows];
//   updatedFinanceRows[index].selectValue = value;
//   const updatedState = { ...currentTabState, financeRows: updatedFinanceRows };
//   setCurrentTabState(updatedState);
//   saveTabData(updatedState);
// };

const handleSelectChange = useCallback((value, index) => {
  onFetchFincncialCost();
  const updatedFinanceRows = [...currentTabState.financeRows];
  updatedFinanceRows[index].selectValue = value;
  const updatedState = { ...currentTabState, financeRows: updatedFinanceRows };
  setCurrentTabState(updatedState);
  saveTabData(updatedState); // Only save if the state actually changed
}, [currentTabState, saveTabData]);


const handleMenuOpen = () => {
  onFetchFincncialCost(); // Dispatch the refetch action when the menu opens
};

const handleFinanceLeadMenuOpen=()=>{
  dispatch(getFinancialLeadList());
}

const handleTextChange = (e, index) => {
  const updatedRows = [...financeRows];
  updatedRows[index].textValue = e.target.value;
  setFinanceRows(updatedRows);
};


const getAvailableOptions = (index) => {
  if (!currentTabState.financeRows[index]) {
    console.warn("Invalid index passed to getAvailableOptions");
    return [];
  }

  const selectedValues = currentTabState.financeRows
    .map((row) => row?.selectValue?.value) // Safeguard for undefined rows
    .filter(Boolean); // Remove null/undefined values

  return financialOptions.filter((option) => 
    !selectedValues.includes(option.value) || 
    currentTabState.financeRows[index]?.selectValue?.value === option.value
  );
};


// const validateFieldsdata = () => {
//   // Validate fields specific to the first tab in jsonData
//   if (activeTab === jsonData[0].name) {
//     if (!currentTabState.downpayment || currentTabState.downpayment.toString().trim() === "") {
//       setDownPaymentError(true);
//       return false;
//     }

//     if (!currentTabState.termofloan || currentTabState.termofloan.toString().trim() === "") {
//       setTermOfLoanError(true);
//       return false;
//     }

//     if (!currentTabState.interestpercentage || currentTabState.interestpercentage.toString().trim() === "") {
//       setInterestPercentageError(true);
//       return false;
//     }

//     if (
//       !currentTabState?.[activeTab]?.financelead || 
//       currentTabState[activeTab].financelead?.value === "Deselect" || 
//       currentTabState[activeTab].financelead?.value.trim() === ""
//     ) {
//       setFinancialError(true);
//       return false;
//     }
//   }

//   // All validations passed
//   return true;
// };

// const validateFieldsdata = () => {
//   let isValid = true;

//    // Check if any of the three fields is filled
//   const hasAnyValue = 
//     (currentTabState?.downpayment && currentTabState.downpayment.toString().trim() !== "") ||
//     (currentTabState?.termofloan && currentTabState.termofloan.toString().trim() !== "") ||
//     (currentTabState?.interestpercentage && currentTabState.interestpercentage.toString().trim() !== "");

//   console.log("hasAnyValue:", hasAnyValue); // Debugging log
//   // Check if all three fields are filled
//   const hasAllValues = 
//   currentTabState?.downpayment && currentTabState.downpayment.toString().trim() !== "" &&
//   currentTabState?.termofloan && currentTabState.termofloan.toString().trim() !== "" &&
//   currentTabState?.interestpercentage && currentTabState.interestpercentage.toString().trim() !== "";

//   // Raise error if some fields are filled but not all
//   if (hasAnyValue && !hasAllValues) {
//     Error(
//       "Please fill the required data"
//     );
//     isValid = false;
//   }

//   // if (activeTab === jsonData[0].name) {
//   //   if (
//   //     !currentTabState?.[activeTab]?.financelead || 
//   //     currentTabState[activeTab].financelead?.value === "Deselect" || 
//   //     currentTabState[activeTab].financelead?.value.trim() === ""
//   //   ) {
//   //     setFinancialError(true);
//   //     isValid = false;
//   //   } else {
//   //     setFinancialError(false);
//   //   }
//   // }

  

//   return isValid; // Return validation result
// };



// const validateFieldsAtleastOnedata = () => {
//   // Retrieve data from localStorage
//   const storedData = JSON.parse(localStorage.getItem("tabData"));

//   // Check if at least one tab has valid data
//   let hasValidTab = false;

//   // Loop through each tab in the stored data
//   Object.keys(storedData).forEach((tabName) => {
//     const tabData = storedData[tabName];

//     console.log(`Validating Tab: ${tabName}`, tabData);

//     // Check if this tab has any valid field
//     if (
//       (tabData?.downpayment && tabData.downpayment.toString().trim() !== "") ||
//       (tabData?.termofloan && tabData.termofloan.toString().trim() !== "") ||
//       (tabData?.interestpercentage && tabData.interestpercentage.toString().trim() !== "")
//     ) {
//       hasValidTab = true; // Mark as valid if any field is filled
//     }
//   });

//   // If no valid tab is found, show an error and return false
//   if (!hasValidTab) {
//     Error("At least one tab must have data for downpayment, termofloan, or interestpercentage.");
//     return false;
//   }

//   console.log("Validation Passed: At least one tab has valid data.");
//   return true;
// };



// const handleTabChange = (tab) => {
//   if (!validateFieldsdata()) {
//     return; // Stop if validation fails
//   }
//   // Save the current tab's data before switching
//   saveTabData(currentTabState);

//   // Load the data for the new tab from tabData
//   setCurrentTabState(tabData[tab]);

//   // Set the new active tab
//   setActiveTab(tab);
// };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>Dashboard</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Dashboard</Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div class="card-header">
                    <h3 class="card-title">List Loopnet</h3>
                    <div class="card-tools d-flex">
                      {rowData && rowData.length > 0 ? (
                        <>
                          {selectedRows && selectedRows.length > 0 ? (
                            <button
                              type="button"
                              className="btn btn-primary loopnet_header_button"
                              onClick={exportToCSV}
                            >
                              <i
                                className="fa fa-file-excel mr-2"
                                aria-hidden="true"
                              ></i>
                              Export CSV
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary loopnet_header_button disabled"
                            >
                              <i
                                className="fa fa-file-excel mr-2"
                                aria-hidden="true"
                              ></i>
                              Export CSV
                            </button>
                          )}
                          
                          {tabType != "reject" ? (
                            selectedRows && selectedRows.length > 0 ? (
                              
                              <button
                                type="button"
                                className="btn btn-primary loopnet_header_button ml-3"
                                onClick={downloadPdf}
                              >
                                <i className="fas fa-download mr-2"></i>Download
                                PDF
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary loopnet_header_button ml-3 disabled"
                              >
                                <i className="fas fa-download mr-2"></i>Download
                                PDF
                              </button>
                            )
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {showProgressBar ? (
                        <div
                          style={{
                            width: "60px",
                            height: "60px",
                            marginLeft: "20px",
                          }}
                        >
                          <CircularProgressbar
                            value={(progressbar.value / progressbar.max) * 100}
                            text={`${progressbar.value}/${progressbar.max}`}
                            styles={buildStyles({
                              textColor: "#007BFF",
                              pathColor: "#007BFF",
                              trailColor: "#d6d6d6",
                            })}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">
                        <div className="form-group">
                          <label className="form-label">State</label>
                          <Select
                            value={state}
                            onChange={onChangeSelect}
                            options={stateOptions}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label className="form-label">City</label>
                          <Select
                            value={city ? city : ""}
                            onChange={onChangeSelect}
                            options={cityOptions}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label className="form-label">Property Types</label>
                          {/* <Select
                                                        value={propertType}
                                                        onChange={onChangeSelect}
                                                        options={propertyTypeOptions}
                                                    /> */}
                          <MultiSelectComponent
                            name={"propertType"}
                            options={propertyTypeOptions}
                            value={propertType}
                            citySelectAll={propertTypeSelectAll}
                            onChange={onChangeSelect}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label className="form-label">Property Use</label>
                          <Select
                            value={propertUse}
                            onChange={onChangeSelect}
                            options={propertyUseOptions}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label className="form-label">Price Range</label>
                          <div className="price-range-loopnet">
                            <input
                              type="text"
                              className="form-control min"
                              name="minPrice"
                              value={minPrice}
                              onChange={onChange}
                              placeholder="Min $"
                              autoComplete="nope"
                            />
                            <input
                              type="text"
                              className="form-control max"
                              name="maxPrice"
                              value={maxPrice}
                              onChange={onChange}
                              placeholder="Max $"
                              autoComplete="nope"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-3 d-flex align-items-end">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-primary mr-2"
                            onClick={onSearch}
                          >
                            <i className="fa fa-search mr-2"></i>Search
                          </button>
                          {resetFilter ? (
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={EmptyFilter}
                            >
                              <i className="fa fa-times-circle mr-2"></i>Reset
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-12" >
                        <div
                         class="card card-primary card-outline card-outline-tabs"
                        //  className="card card-primary card-outline card-tabs"
                         >
                          <div class="card-header p-0 border-bottom-0">
                            <ul
                              class="nav nav-tabs"
                              id="custom-tabs-four-tab"
                              role="tablist"
                            >
                              <li class="nav-item">
                                <a
                                  class="nav-link active"
                                  onClick={() => onChangeTab("new")}
                                  id="custom-tabs-four-home-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-home"
                                  role="tab"
                                  aria-controls="custom-tabs-four-home"
                                  aria-selected="false"
                                >
                                  New
                                </a>
                              </li>
                              {/* <li class="nav-item">
                                                                <a class="nav-link" onClick={() => onChangeTab("exportcsv")} id="custom-tabs-four-tab" data-toggle="pill" href="#custom-tabs-four" role="tab" aria-controls="custom-tabs-four" aria-selected="false">Export CSV</a>
                                                            </li> */}
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("downloadpdf")}
                                  id="custom-tabs-four-profile-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-profile"
                                  role="tab"
                                  aria-controls="custom-tabs-four-profile"
                                  aria-selected="false"
                                >
                                 New Download 
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("reject")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Reject
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("cre")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  CRE
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("credownload")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  CRE Download
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("business")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Business
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("businessdownload")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Business Download
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("multifamily")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Multifamily
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("multifamilydownload")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Multifamily Download
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("land")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Land
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  onClick={() => onChangeTab("landdownload")}
                                  id="custom-tabs-four-messages-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-four-messages"
                                  role="tab"
                                  aria-controls="custom-tabs-four-messages"
                                  aria-selected="false"
                                >
                                  Land Download
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="card-body">
                            <RowSelectAggridComponent
                              perPage={perPage}
                              currentPage={currentPage}
                              onRowClicked={onRowClicked}
                              onGridReady={onGridReady}
                              onSelectionChanged={onSelectionChanged}
                              rowData={rowData}
                              defaultColDef={defaultColDef}
                              columnDefs={columnDefs}
                              total={total}
                              onPagination={onPagination}
                              onPerPageChange={onPerPageChange}
                              gridApiRef={gridApiRef}
                              onFilterChanged={onFilterChanged}
                              onSortChanged={onSortChanged}
                              getRowStyle={getRowStyle}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SpinnerComponent spinner={spinner || listSpinner} />
      </div>

      <ViewLoopNet
        rejected={onChangeStatus}
        tabType={tabType}
        data={viewData}
        handleCloseModal={handleCloseViewModal}
        showModal={showViewModal}
      />
{/* old structure modal----------------------------------------- */}
<Modal show={showOldModal}>
        <Modal.Header>
          <Modal.Title>Create PDF</Modal.Title>
          <i
            className="fa fa-times close_icon_modal"
            onClick={handleCloseOldModal}
          ></i>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Buyer's Equity
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="downpayment"
                    value={downpayment}
                    onChange={onChange}
                    placeholder="Enter Buyer's Equity"
                    autoComplete="nope"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fa fa-percent"></i>
                    </span>
                  </div>
                </div>
                <div
                  className="invalid-feedback"
                  style={{ display: downpaymentError ? "block" : "none" }}
                >
                  Buyer's Equity is required
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Term Of Loan In Years
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="termofloan"
                  value={termofloan}
                  onChange={onChange}
                  placeholder="Enter Term Of Loan In Years"
                  autoComplete="nope"
                />
                <div
                  className="invalid-feedback"
                  style={{ display: termofloanError ? "block" : "none" }}
                >
                  Term Of Loan In Years is required
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Interest Percentage
                  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="interestpercentage"
                    value={interestpercentage}
                    onChange={onChange}
                    placeholder="Interest Percentage"
                    autoComplete="nope"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fa fa-percent"></i>
                    </span>
                  </div>
                </div>
                <div
                  className="invalid-feedback"
                  style={{
                    display: interestpercentageError ? "block" : "none",
                  }}
                >
                  Interest Percentage is required
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  CapEx, Working Capital, Closing Costs
                </label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    name="additionalcost"
                    value={additionalcost}
                    onChange={onChange}
                    placeholder="Enter additional costs"
                    autoComplete="nope"
                  />
                </div>
              </div>
            </div>


            <div className="col-12">
            <div className="form-group">
                <label className="form-label">Financing Options Leads  <span className="ml-1" style={{ color: "red" }}>
                    *
                  </span></label>
                <Select
                            value={financelead ? financelead : ""}
                            onChange={onChangeSelect}
                            options={fincialleadOptions}
                          />
                 <div
                  className="invalid-feedback"
                  style={{ display: FinancialError ? "block" : "none" }}
                >
                  Financial Lead is required
                </div>
            </div>
        </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOldModal}>
            <i className="fa fa-times mr-2"></i>Close
          </Button>
          {saving ? (
            <Button variant="primary">
              <i className="fa fa-spinner fa-spin mr-2"></i>Downloading
            </Button>
          ) : (
            <Button variant="primary" onClick={handleOldModalSubmit}>
              <i className="fas fa-download mr-2"></i>Download
            </Button>
          )}
        </Modal.Footer>
      </Modal>
{/* new structure --------------------------------modal */}
      <Modal show={showModal}>
        <Modal.Header>
          <Modal.Title>Create PDF</Modal.Title>          
  <div className="tabs">
  {jsonData.map((tab) => (
    <button key={tab.name} className={`tab-button ${activeTab === tab.name ? "active" : ""}`}
      onClick={() => { 
        // if (!validateFieldsdata()) 
        //   {return;  } 
        setActiveTab(tab.name)}}>
      {tab.name.toUpperCase()} </button>
    ))}
  </div>
        <i  className="fa fa-times close_icon_modal" onClick={handleCloseModal}></i>          
        </Modal.Header>
        <Modal.Body>       
          <div className="row" style={{
        maxHeight: '460px', // Adjust the max height as per your requirement
        overflowY: 'auto',
        border: '1px solid #ddd',
        padding: '10px',
        borderRadius: '5px',
      }}>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Buyer's Equity<span className="ml-1" style={{ color: "red" }}>* </span>
                </label>
                <div class="input-group">
                  <input type="text" className="form-control" name="downpayment"  value={currentTabState.downpayment}
                  onChange={handleInputChange} placeholder="Enter Buyer's Equity" autoComplete="nope"/>
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-percent"></i></span>
                  </div>
                </div>
                <div className="invalid-feedback" style={{ display: downpaymentError ? "block" : "none" }}>
                  Buyer's Equity is required  </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Term Of Loan In Years<span className="ml-1" style={{ color: "red" }}> * </span> </label>
                <input type="text" className="form-control" name="termofloan" value={currentTabState.termofloan}
                  onChange={handleInputChange} placeholder="Enter Term Of Loan In Years" autoComplete="nope"
                />
                <div className="invalid-feedback" style={{ display: termofloanError ? "block" : "none" }}>
                  Term Of Loan In Years is required
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label className="form-label">
                  Interest Percentage
                  <span className="ml-1" style={{ color: "red" }}> * </span></label>
                <div class="input-group">
                  <input type="text" className="form-control" name="interestpercentage"                    
                    value={currentTabState.interestpercentage}
                    onChange={handleInputChange}
                    placeholder="Interest Percentage"
                    autoComplete="nope" />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fa fa-percent"></i>
                    </span>
                  </div>
                </div>
                <div
                  className="invalid-feedback"
                  style={{display: interestpercentageError ? "block" : "none",}}>
                  Interest Percentage is required
                </div>
              </div>
            </div>          

{/* newly added code for dynamic inputs--------------mansha--------13 december 24 */}
<div className="col-12">
      <div className="form-group">
        <label className="form-label">Financial Categories</label>       
        {currentTabState.financeRows.map((row, index) => (
          <div key={index} className="d-flex align-items-center mb-3">          
            <Select
            value={row.selectValue || null} // Ensure it's either the selected option or null
            onChange={(value) => handleSelectChange(value, index)} // Trigger change handler
            options={getAvailableOptions(index)} // Filtered options
            className="mr-2 select-width"
            placeholder="Select"
            onMenuOpen={handleMenuOpen}
            menuPortalTarget={document.body} // Render dropdown outside the container
            menuPlacement="auto" // Automatically adjust placement
            menuPosition="fixed"
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 1050, // Ensure it appears above the modal
                
              }),
              menu: (base) => ({
                ...base,
                maxHeight: '150px',
                overflowY: 'auto',
              }),
            }}
          />
            <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control mr-2 input-width"
                    value={row.textValue}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Validate input: Only allow numbers and dots, and prevent leading spaces
                      const validValue = inputValue.replace(/[^0-9.]/g, '').replace(/^\s+/, '');
                      // Update the state with the valid value
                      const updatedFinanceRows = [...currentTabState.financeRows];
                      updatedFinanceRows[index].textValue = validValue;
                      const updatedState = { ...currentTabState, financeRows: updatedFinanceRows };
                      setCurrentTabState(updatedState);
                      saveTabData(updatedState);
                    }}
                    placeholder="Enter additional costs"/>

            </div>
            <button  type="button" className="btn btn-default mr-1" onClick={handleAddRow} >
              <i className="fa fa-plus"></i>
            </button>
            {currentTabState.financeRows.length > 1 && (
              <button type="button" className="btn btn-default" onClick={() => handleRemoveRow(index)}>
                <i className="fa fa-minus"></i>
              </button>
            )}
          </div>
        ))}
       
      </div>
    </div>  
{/* <div className="col-12">
  <div className="form-group">
    <label className="form-label">
      Financing Options Leads  
      <span className="ml-1" style={{ color: "red" }}>*</span>
    </label>   
      <Select
        options={fincialleadOptions || []}
        value={
          currentTabState?.[activeTab]?.financelead
        }
        onChange={(value) => {
          const updatedState = {
            ...currentTabState,
            [activeTab]: {
              ...currentTabState[activeTab],
              financelead: value,
            },
          };
          setCurrentTabState(updatedState);
          saveTabData(updatedState);
          setFinancialError(false); // Clear error when valid input is selected
        }}
      // isDisabled={activeTab !== "Conventional"}
      />
      <div className="invalid-feedback" style={{ display: FinancialError ? "block" : "none" }}>
        Financial Lead is required
      </div>
  </div>
</div> */}

<div className="col-12">
      <div className="form-group" >
        <label className="form-label">
          Financing Options Leads
          <span className="ml-1" style={{ color: "red" }}>
            *
          </span>
        </label>
        <Select
  options={fincialleadOptions || []}
  value={financeLead}
  onChange={(selectedOption) => {            
    setFinanceLead(selectedOption);
    setFinancialError(false);
  }}
  onMenuOpen={handleFinanceLeadMenuOpen}
  menuPortalTarget={document.body}
  menuPlacement="auto" // Automatically adjust placement
  menuPosition="fixed"
  styles={{
    menuPortal: (base) => ({
      ...base,
      zIndex: 1050, // Ensure it appears above the modal
      
    }),
    menu: (base) => ({
      ...base,
      maxHeight: '150px',
      overflowY: 'auto',
    }),
  }}
/>



        <div
          className="invalid-feedback"
          style={{ display: FinancialError ? "block" : "none" }}
        >
          Financial Lead is required
        </div>
      </div>
    </div>

          </div>
        </Modal.Body>
          <Modal.Footer>
          <div style={{ display: "flex",  gap: "10px"}}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
           </div>
           {/* {activeTab === jsonData[jsonData.length - 1].name && ( */}
  <>
    <Button variant="secondary" onClick={handleCloseModal}>
      <i className="fa fa-times mr-2"></i>Close
    </Button>

    {/* Conditionally disable the download button if any required field is blank */}
    {saving ? (
  <Button variant="primary">
    <i className="fa fa-spinner fa-spin mr-2"></i>Downloading
  </Button>
) : (
  <Button
    variant="primary"
    onClick={handleSubmit}
    disabled={
      !(
        currentTabState?.downpayment &&
        currentTabState.downpayment.toString().trim() !== "" &&
        currentTabState?.termofloan &&
        currentTabState.termofloan.toString().trim() !== "" &&
        currentTabState?.interestpercentage &&
        currentTabState.interestpercentage.toString().trim() !== "" &&
        financeLead && // Add check for financingLead state
        financeLead.toString().trim() !== "" // Ensure financingLead is not empty
      )
    }
  >
    {/* Conditionally render the OverlayTrigger only when the button is disabled */}
    {(!(
      currentTabState?.downpayment &&
      currentTabState.downpayment.toString().trim() !== "" &&
      currentTabState?.termofloan &&
      currentTabState.termofloan.toString().trim() !== "" &&
      currentTabState?.interestpercentage &&
      currentTabState.interestpercentage.toString().trim() !== "" &&
      financeLead && 
      financeLead.toString().trim() !== ""
    )) && (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-disabled">
            Please fill the required data
          </Tooltip>
        }
      >
        <div>
          <i className="fas fa-download mr-2"></i>Download
        </div>
      </OverlayTrigger>
    )}
    {(
      currentTabState?.downpayment &&
      currentTabState.downpayment.toString().trim() !== "" &&
      currentTabState?.termofloan &&
      currentTabState.termofloan.toString().trim() !== "" &&
      currentTabState?.interestpercentage &&
      currentTabState.interestpercentage.toString().trim() !== "" &&
      financeLead && 
      financeLead.toString().trim() !== ""
    ) && (
      <div>
        <i className="fas fa-download mr-2"></i>Download
      </div>
    )}
  </Button>
)}

  </>
 {/* )} */}




</div>
      </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListLoopnet;
