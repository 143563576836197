import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SpinnerComponent from '../../Utilities/Spinner/spinner';
import RowSelectAggridComponent from '../../Utilities/Ag-Grid/rowselectaggridcomponents';
import axios from 'axios';
import ImportedURL from '../../Common/api';
import { Error, Success } from '../../Common/swal';
import Swal from 'sweetalert2';
import AddUser from './addfinanciallead';
import { fetchfinancealleadData, fetchResetUser, fetchFinancialLeadSpinner, fetchViewFinancialLead } from '../../Action/financialledAction';
import ViewUser from './viewfinanciallead';
import { getSortModelAlternative } from '../../Common/validate'; 

const defaultColDef = {editable: false,sortable: true,resizable: true,filter: true,flex: 1,minWidth: 100,
    filterParams: { newRowsAction: 'keep' },}
const Listfinanciallead = () => {
    const gridApiRef = useRef(null);
    const [perPage, setPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [gridApi, setGridApi] = useState(null);
    const [listSpinner, setListSpinner] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [modalType, setModalType] = useState("Add");
    const [sort, setSort] = useState("");
    const [id, setId] = useState("");
    const [columnDefs] = useState([
        {
            headerName: "Name", field: "name", sortable: true, floatingFilter: true,
            valueGetter: function (params) {
                return params.data && params.data.name ? params.data.name : '---'
            }
        },
        {
            headerName: "Email", field: "email", sortable: true, floatingFilter: true,
            valueGetter: function (params) {
                return params.data && params.data.email ? params.data.email : '---'
            }
        },
        {
            headerName: "Mobile No", field: "mobile", sortable: false, floatingFilter: true,
            valueGetter: function (params) {
                return params.data && params.data.mobile ? params.data.mobile : '---'
            }
        },
        {
            headerName: "Address", field: "address", sortable: false, floatingFilter: true,
            valueGetter: function (params) {
                return params.data && params.data.address ? params.data.address : '---'
            }
        },
        {
            headerName: "Status", field: "status", sortable: false, floatingFilter: false,
            cellRenderer: function (params) {
                let btn = `---`;
                if (params.data) {
                    if (params.data.status) {
                        btn = `<button type="button" title="Status" class="btn btn-success btn-xs user-status-btn" data-action-type="Status">Active</button>`
                    } else {
                        btn = `<button type="button" title="Status" class="btn btn-danger btn-xs user-status-btn" data-action-type="Status">Inactive</button>`
                    }
                }
                return <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: btn }}></div>;
            }
        },
        {
            headerName: 'Actions', field: 'actions', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return <div dangerouslySetInnerHTML={{ __html: params.value }}></div>;
            }
        },
    ]);
    const dispatch = useDispatch();
    const onFetchData = (query = {}) => {
        const formData = {
            perPage: perPage,
            currentPage: currentPage,
            filter: filter,
            sort: sort,
            ...query 
        }
        dispatch(fetchFinancialLeadSpinner());
        dispatch(fetchfinancealleadData(formData));
    }
    useEffect(() => {
        onFetchData();
    }, []);
   // const data = useSelector(state => state.user);
    const financeleaddata = useSelector((state) => state.financelead); //financelead uses rootreducer name
    const rowData = financeleaddata.financeLeads ? financeleaddata.financeLeads : [];
    const total = financeleaddata.total ? financeleaddata.total : 0;
    const spinner = financeleaddata.spinner ? financeleaddata.spinner : false; 
    const edit = `<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#viewmodal"><i class="fas fa-edit" data-action-type="Edit"></i></button>`;
    const view = `<button title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-eye" data-action-type="View"></i></button>`;
    if (rowData && rowData.length > 0) {
        rowData.forEach(object => {
            object.actions = edit + view;
        });
    }
    const onRowClicked = async (event) => {
        const { _id, status } = event.data;       
        const value = event.event.target.getAttribute('data-action-type');
        if (value === 'Edit') {            
            dispatch(fetchViewFinancialLead(event.data));
            setId(_id);
            setModalType("Edit");
            setShowModal(true)
        }
        if (value === 'View') {
            dispatch(fetchViewFinancialLead(event.data));
            setShowViewModal(true)
        }
        if (value === 'Status') {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: "btn btn-primary ",
                    cancelButton: "btn btn-default mr-3"
                },
                buttonsStyling: false
            });
            swalWithBootstrapButtons.fire({
                title: "Are you sure to change the status?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    setListSpinner(true);
                    console.log(_id);
                    axios.post(ImportedURL.API.changefinanceleadstatus, { _id: _id, status: !status })
                        .then((res) => {
                            onFetchData();
                            Success("Status updated successfully");
                            setListSpinner(false);
                        }).catch(({ response }) => {
                            const { message } = response.data ? response.data : {}
                            Error(message);
                            setListSpinner(false);
                        });
                }
            });
        }
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const onPagination = (page) => {
        setCurrentPage(page);
        onFetchData({ currentPage: page });
    };

    const onPerPageChange = (num) => {
        setCurrentPage(1);
        setPerPage(num);
        onFetchData({ currentPage: 1, perPage: num });
    };

    const onFilterChanged = (event) => {
        const newFilter = event.api.getFilterModel();
        setCurrentPage(1);
        setFilter(newFilter);
        onFetchData({ currentPage: 1, filter: newFilter });
    };

    const onSortChanged = (event) => {
        const sortModel = getSortModelAlternative(event.columnApi);
        setCurrentPage(1);
        setSort(sortModel);
        onFetchData({currentPage: 1,sort: sortModel,});
    };

    const addUser = () => {
        dispatch(fetchResetUser());
        setShowModal(true);
        setModalType("Add");
    }

    const handleClose = (fetchdata = false) => {
        if (fetchdata) {
            onFetchData();
        }
        setShowModal(false);
    }
    const handleCloseView = () => {
        setShowViewModal(false);
    }
    return (
        <>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-6">
                                <h1 class="m-0">List User</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item"><Link to={"/"}>Dashboard</Link></li>
                                    <li class="breadcrumb-item active">List User</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="content">
                    <div class="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div class="card-header">
                                        <div class="card-tools d-flex">
                                            <button type="button" className="btn btn-primary" onClick={addUser}><i className="fa fa-plus mr-2" aria-hidden="true"></i>Add</button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <RowSelectAggridComponent
                                                perPage={perPage}
                                                currentPage={currentPage}
                                                onRowClicked={onRowClicked}
                                                onGridReady={onGridReady}
                                                rowData={rowData}
                                                defaultColDef={defaultColDef}
                                                columnDefs={columnDefs}
                                                total={total}
                                                onPagination={onPagination}
                                                onPerPageChange={onPerPageChange}
                                                gridApiRef={gridApiRef}
                                                onFilterChanged={onFilterChanged}
                                                onSortChanged={onSortChanged}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SpinnerComponent spinner={spinner || listSpinner} />
                </section >
            </div >

            <AddUser show={showModal} handleClose={handleClose} modalType={modalType} id={id} />
            <ViewUser show={showViewModal} handleClose={handleCloseView} />
        </>
    )
}

export default Listfinanciallead
