import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import Logo from '../../Assets/Images/cornovuscapital-logo-2.webp';
import { useSelector } from 'react-redux';

const Sidebar = () => {
    const accountReducer = useSelector(state => state.account);
    const account = accountReducer.account ? accountReducer.account : {};
    const location = useLocation();
    const currentPath = location.pathname;
    const pathSegment = currentPath ? currentPath.split('/')[1] : '';

    return (
        <>
            <aside class="main-sidebar sidebar-dark-primary elevation-4">
                <Link to="/" class="brand-link">
                    <img src={Logo} alt="AdminLTE Logo" width={"220px"} />
                </Link>
                <div class="sidebar">
                    <nav class="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <NavLink to="/" className={(["", "view-loopnet"].includes(pathSegment) ? "nav-link active" : "nav-link")} >
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>Dashboard</p>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/fetch-data" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                    <i className="nav-icon fa fa-link"></i>
                                    <p>Fetch Data</p>
                                </NavLink>
                            </li>
                            {
                                account.role == "admin"
                                    ?
                                    <li className="nav-item">
                                        <NavLink to="/list-user" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                            <i class="nav-icon fas fa-user"></i>
                                            <p>Users</p>
                                        </NavLink>
                                    </li>
                                    : ""
                            }
                            {
                                account.role == "admin"
                                    ?
                                    <li className="nav-item">
                                        <NavLink to="/list-financiallead" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                            <i class="nav-icon fas fa-user"></i>
                                            <p>Financial Lead</p>
                                        </NavLink>
                                    </li>
                                    : ""
                            }
                            {
                                account.role == "admin"
                                    ?
                                    <li className="nav-item">
                                        <NavLink to="/list-financialcost" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                            <i class="nav-icon fas fa-user"></i>
                                            <p>Financial Categories</p>
                                        </NavLink>
                                    </li>
                                    : ""
                            }
                            <li className="nav-item">
                                <NavLink to="/change-password" className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
                                    <i className="nav-icon fas fa-lock"></i>
                                    <p>Change Password</p>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>

        </>
    )
}

export default Sidebar
