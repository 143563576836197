import React from 'react'
import moment from 'moment';

const Footer = () => {
    const currentYear = moment().format('YYYY');

    return (
        <>
            <footer class="main-footer">
                <div class="float-right d-none d-sm-block">
                    <b>Version</b> 0.0.1
                </div>
                <strong>Copyright &copy; {currentYear}<a href="https://www.cornovuscapital.com/" target='_blank'>Cornovus Capital</a>.</strong> All rights reserved. 
                Developed by <a href="https://www.acwcircle.com/" target='_blank'>acwcircle.com</a>
            </footer>

            <aside class="control-sidebar control-sidebar-dark">
            </aside>
        </>
    )
}

export default Footer
