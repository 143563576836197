import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHandleInputUser } from '../../Action/financialledAction';
import ImportedURL from '../../Common/api';
import { Error, Success } from '../../Common/swal';
import axios from 'axios';
import validator from 'validator';

const Addfinanciallead = ({ show, handleClose, modalType, id = '' }) => {
    const userReducer = useSelector(state => state.financeconv);
    const data = userReducer.user ? userReducer.user : {};
    const dispatch = useDispatch();

    const [nameError, setNameError] = useState(false);
     const [savingSpinner, setSaveSpinner] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;
        dispatch(fetchHandleInputUser(name, value));
    
        // Reset error states on change
        setNameError(false);    
        // Check for errors based on the field name
        switch (name) {
            case "name":
                if (!value) {
                    setNameError(true);
                }
                break;
           
            default:
                break;
        }
    };  

    const submit = () => {
        let newData = { ...data };
        for (let key in newData) {
            if (newData.hasOwnProperty(key) && typeof newData[key] === 'string') {
                newData[key] = newData[key].trim();
            }
        }
    
        let valid = 1; // Start as valid
        // Validate fields
        if (!newData.name) {
            setNameError(true);
            valid = 0; // Set valid to false
        }
    
        if (valid) {
            setSaveSpinner(true);
            const apiCall = modalType === "Add"
                ? axios.post(ImportedURL.API.addfinancialconv, newData)
                : axios.post(`${ImportedURL.API.updatefinancialconv}/${id}`, newData);
    
            apiCall.then((res) => {
                const { message } = res.data || {};
                Success(message);
                reset(); // Reset only after successful submission
                handleClose(true);
            }).catch(({ response }) => {
                const { message } = response.data || {};
                Error(message);
            }).finally(() => {
                setSaveSpinner(false);
            });
        }
    };  

    const reset = () => {
        setNameError(false);
    }
    const handleCloseSubmit = () => {
        handleClose();
        reset();
    }
    return (
        <>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>{modalType + " Financial Categories"}</Modal.Title>
                    <i className="fa fa-times close_icon_modal" onClick={handleCloseSubmit}></i>
                </Modal.Header>
                <Modal.Body>
                    {/* {savingSpinner && <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>} */}
                    <div className="">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                    <input type="text" className="form-control" name="name" value={data.name || ""} onChange={onChange} placeholder="Name" />
                                    <div className="invalid-feedback" style={{ display: nameError ? "block" : 'none' }}>Name is required</div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSubmit}>
                        <i className="fa fa-times mr-2"></i>Close
                    </Button>
                    {savingSpinner ?
                        <Button variant="primary">
                            <i className="fa fa-spinner fa-spin mr-2"></i>{modalType == "Add" ? "Saving" : "Updating"}
                        </Button>
                        :
                        <Button variant="primary" onClick={submit}>
                            <i className="fas fa-download mr-2"></i>{modalType == "Add" ? "Save" : "Update"}
                        </Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Addfinanciallead;
