import { combineReducers } from 'redux';
import loopnet from './loopnet';
import dashboard from './dashboard';
import cities from './cities';
import user from './user';
import account from './account';
import financelead from './financiallead';
import financeconv from './financialconversion'

const rootReducer = combineReducers({
    loopnet,
    dashboard,
    cities,
    user,
    account,
    financelead,
    financeconv
});

export default rootReducer;